import type {
  LoginRequest,
  RegisterRequest,
  VerifyRequest,
  ForgotPasswordRequest,
  ResetPasswordRequest,
  WithTsToken,
} from '~/api/types/Authentication'
import type { UserData } from '~/api/types/Authentication'

export default function () {
  const router = useRouter()
  const config = useRuntimeConfig()
  const { authentication } = useApi()
  const user = useUser()
  const isAuthenticated = computed(() => !!user.value)

  async function fetchUser(): Promise<any> {
    const userData: UserData = await authentication.user()
    user.value = userData.data
  }

  async function login(data: LoginRequest & WithTsToken): Promise<any> {
    if (isAuthenticated.value) {
      return
    }

    await useTurnstile(data)
    delete data.ts_token

    await authentication.login(data)
    await fetchUser()

    await router.push(config.public.api.redirects.onLogin)
  }

  async function register(data: RegisterRequest & WithTsToken): Promise<any> {
    await useTurnstile(data)
    delete data.ts_token

    await authentication.register(data)

    await $fetch(`/api/zendesk/user`, {
      body: data,
      method: 'POST',
    })

    await fetchUser()
    await router.push(config.public.api.redirects.onLogin)
  }

  async function logout(): Promise<any> {
    if (!isAuthenticated.value) {
      return
    }

    await authentication.logout()
    user.value = null

    await router.push(config.public.api.redirects.onLogout)
  }

  async function verify(data: VerifyRequest & WithTsToken): Promise<any> {
    await useTurnstile(data)
    delete data.ts_token

    await authentication.verify(data)
    await fetchUser()
    await router.push(config.public.api.redirects.onLogin)
  }

  async function resendVerify(): Promise<any> {
    await authentication.resendVerify()
  }

  async function forgotPassword(data: ForgotPasswordRequest & WithTsToken): Promise<any> {
    await useTurnstile(data)
    delete data.ts_token

    await authentication.forgotPassword({
      email: data.email,
    })
  }

  async function resetPassword(data: ResetPasswordRequest & WithTsToken): Promise<any> {
    await useTurnstile(data)
    delete data.ts_token

    await authentication.resetPassword(data)
  }

  return {
    user,
    isAuthenticated,
    login,
    register,
    logout,
    verify,
    resendVerify,
    forgotPassword,
    resetPassword,
  }
}
